import * as React from 'react';
import './App.css';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import Navigation from '@mui/icons-material/Navigation'
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Link from '@mui/material/Link';
import AlertTitle from '@mui/material/AlertTitle';

function App() {
    return (
        <div className="App">
            <div className="AppContainer">
                <Stack spacing={2}>
                    <Typography variant="h1">
                        ASE Team 2 🚀
                    </Typography>
                    <Alert severity="success">If you can see this, the CI/CD Pipeline worked! 🥳</Alert>
                    <Paper style={{ width: 'fit-content', padding: 5 }}>
                        This is using Material UI Components for some fancy UI 🔥<br/>
                        Check out which MUI Components are available <Link target="_blank" rel="noreferrer" href="https://mui.com/components/">here</Link>.
                    </Paper>
                    <Alert severity="info">Examples:</Alert>
                    <Card sx={{ maxWidth: 345, my: '10px' }}>
                        <CardMedia
                            component="img"
                            height="140"
                            image="https://mui.com/static/images/cards/contemplative-reptile.jpg"
                            alt="green iguana"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Lizard
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Lizards are a widespread group of squamate reptiles, with over 6,000
                                species, ranging across all continents except Antarctica
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button size="small">Share</Button>
                            <Button size="small">Learn More</Button>
                        </CardActions>
                    </Card>
                    <Fab variant="extended" sx={{ width: 'fit-content' }}>
                        <Navigation sx={{ mr: 1 }} />
                        Navigate
                    </Fab>
                    <div>
                        <TextField required variant="outlined" label="Enter a good joke" />
                    </div>
                    <Stack spacing={1}>
                        <Skeleton variant="text" width={210} />
                        <Skeleton variant="circular" width={40} height={40} />
                        <Skeleton variant="rectangular" width={210} height={118} />
                    </Stack>
                    ... and so on.
                    <Alert severity="success">
                        <AlertTitle>Check out App.tsx for how these Components are used</AlertTitle>
                        But please keep in mind that is a demo only. Do <strong>not</strong> import and use thousands of cmps like here.<br />
                        In reality, you would create own cmps for each example and in those cmps you import the mui cmps you need.<br />
                        You then import your own cmps for a clean structure like this:
                        <div style={{ backgroundColor: '#eeeeee', fontFamily: 'monospace', width: '600px' }}>
                            {"import MyComponent1 from 'components/MyComponent1';"}<br />
                            {"import { MyComponent2, MyComponent3 } from 'components/MyComponent';"}<br /><br />
                            {"<MyComponent1/>"}<br />
                            {"<MyComponent2/>"}<br />
                            {"<MyComponent3/>"}
                        </div>
                    </Alert>
                </Stack>
            </div>
        </div>
    );
}

export default App;
